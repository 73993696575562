@use "../breakpoints" as *;

.section-intro {
    .rainbow-title {
        p {
            font-size: var(--text-xl);
            line-height: var(--text-xl);
            font-weight: var(--text-medium);

            @media screen and (min-width: $media-md) {
                font-size: var(--text-xxl);
                line-height: var(--text-xxl);
            }

            @media screen and (min-width: $media-lg) {
                font-size: var(--text-2xxl);
                line-height: 89px;
            }

            &:nth-child(1n) {
                color: rgb(var(--rainbow-blue))
            }
            &:nth-child(2n) {
                color: rgb(var(--rainbow-green))
            }
            &:nth-child(3n) {
                color: rgb(var(--rainbow-yellow))
            }
            &:nth-child(4n) {
                color: rgb(var(--rainbow-orange))
            }
            &:nth-child(5n) {
                color: rgb(var(--rainbow-red))
            }
            &:nth-child(6n) {
                color: rgb(var(--rainbow-purple))
            }
            &:nth-child(7n) {
                color: rgb(var(--rainbow-pink))
            }
        }
     }
}

.section-coloring {
    .title {
        span {
            color: rgb(var(--rainbow-blue));
            font-weight: var(--text-light);
            font-size: var(--text-xl);
            line-height: var(--text-xl);
    
            @media screen and (min-width: $media-md) {
                font-size: var(--text-xxl);
                line-height: var(--text-xxl);
            }
    
            @media screen and (min-width: $media-lg) {
                font-size: var(--text-2xxl);
                line-height: 89px;
            }
        }
    }
}

.big-quote p {
    color: rgb(var(--white));
    span {
        color: rgb(var(--rainbow-yellow));
        span {
            color: rgb(var(--white));
            font-weight: var(--text-black);
            line-height: 1.5;
            
            @media screen and (min-width: $media-xl) {
                font-size: var(--text-3xl);
                line-height: 129px;
            }
        }
    }
}